import React from "react";
import clsx from "clsx";

import { Asset, Decorator, IconButton, RichText } from "components";

function PageHero({
  heroContent,
  heroDecorators,
  heroHeading,
  heroImage,
  jumpLinks,
}) {
  if (heroHeading)
    return (
      <header className="pb-12 dark lg:pb-32">
        <div className="pt-32 -mt-32 dark:bg-darkPlum">
          <div className="relative pt-10 wrapper md:pt-24 default-grid">
            {heroDecorators && heroDecorators[0] && (
              <Decorator
                className="absolute hidden -translate-x-full top-4 sm:left-fluidMargin xl:left-26 md:block"
                asset={heroDecorators && heroDecorators[0].asset}
                size="small"
              />
            )}
            <div className="space-y-8 col-span-full md:col-span-6">

              <div className="space-y-6 md:pr-8 pb-14 md:pb-24">
                {heroHeading && (
                  <h1 className="h1 text-darkPurple dark:text-thistle">
                    {heroHeading}
                  </h1>
                )}
                {heroContent && (
                  <RichText
                    className="max-w-3xl"
                    content={heroContent}
                    size="large"
                  />
                )}
                {jumpLinks && (
                  <ul className="space-y-6">
                    {jumpLinks?.map(({ label, link }, i) => {
                      return (
                        <li key={`heroNavLink-${i}`}>
                          <IconButton
                            label={label}
                            href={link?.link}
                            icon="arrow-down"
                            animate="down"
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {heroImage && (
              <div
                className={clsx(
                  "self-end col-span-full md:col-span-6 md:col-start-7",
                  heroImage ? "block" : "hidden md:block"
                )}
              >
                <div className="-mx-6 sm:-mx-fluidMargin md:ml-0 xl:-mr-26">
                  <div className="select-none aspect-w-6 aspect-h-5">
                    {heroImage && (
                      <Asset
                        asset={heroImage}
                        objectFit="cover"
                        objectPosition="center"
                        style={{ position: "absolute" }}
                      />
                    )}
                    <div>
                      {heroImage && heroDecorators && heroDecorators[1] && (
                        <div className="absolute left-0 hidden w-40 h-40 transform translate-y-1/2 select-none md:block -translate-x-7 md:-translate-x-1/2 bottom-4 md:left-4 lg:w-44 lg:h-44">
                          <Decorator
                            asset={heroDecorators && heroDecorators[1].asset}
                            size="small"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!heroImage && heroDecorators && heroDecorators[1] && (
              <Decorator
                className="absolute right-0 hidden md:block bottom-24"
                asset={heroDecorators && heroDecorators[1].asset}
                size="large"
              />
            )}
          </div>
        </div>
      </header>
    );
  else return null;
}

export default PageHero;
