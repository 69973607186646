import React from "react";
import { graphql } from "gatsby";

import { SEO as Seo, SliceZone, Subheader } from "components";

import Hero from "./_Hero";

function OverviewPage({ data, pageContext }) {
  let PAGE = {};
  let HEADER = {};

  if (data) {
    PAGE = data.contentfulOverviewPage;
    if (data.contentfulHeader) {
      HEADER = data.contentfulHeader;
    }
  }

  return (
    <>
      <Seo
        title={PAGE?.seoTitle}
        description={PAGE?.seoDescription?.internal?.content}
        image={PAGE?.seoImage}
      />
      <main>
        {HEADER?.overviewSubnavTitle && HEADER?.overviewSubnavLinks && (
          <Subheader
            title={HEADER.overviewSubnavTitle}
            links={HEADER.overviewSubnavLinks}
          />
        )}
        <Hero {...PAGE} />
        <SliceZone {...PAGE} />
      </main>
    </>
  );
}

export const pageQuery = graphql`
  query OverviewPageQuery($slug: String) {
    contentfulHeader {
      overviewSubnavTitle
      overviewSubnavLinks {
        ...LinkFragment
      }
    }
    contentfulOverviewPage(slug: { eq: $slug }) {
      slug
      seoTitle
      seoDescription {
        internal {
          content
        }
      }
      seoImage {
        ...AssetFragment
      }
      heroHeading
      heroContent {
        raw
      }
      jumpLinks {
        ... on ContentfulLink {
          ...LinkFragment
        }
      }
      heroImage {
        gatsbyImageData(width: 1024, layout: FULL_WIDTH)
        ...AssetFragment
      }
      heroDecorators {
        ...DecoratorFragment
      }
      content {
        __typename
        ... on ContentfulAccordion {
          content {
            raw
          }
          accordionItems {
            title
            content {
              raw
              references {
                ... on ContentfulAsset {
                 contentful_id
                 __typename
                 file {
                   url
                 }
               }
              }
            }
          }
          decorators {
            ...DecoratorFragment
          }
        }
        ... on ContentfulCallout {
          heading
          button {
            ...LinkFragment
          }
          decorators {
            ...DecoratorFragment
          }
        }
        ... on ContentfulCertificationList {
          heading
          certifications {
            name
            description
            icon {
              ...AssetFragment
              gatsbyImageData(width: 768)
            }
          }
        }
        ... on ContentfulColumnGrid {
          align
          heading
          subheading {
            raw
          }
          linkCard {
            heading
            image {
              ...AssetFragment
              gatsbyImageData(width: 768)
            }
            link {
              ...LinkFragment
            }
          }
          columnGridCards {
            color
            image {
              ...AssetFragment
              gatsbyImageData(width: 256, layout: FULL_WIDTH)
            }
            text {
              raw
            }
          }
        }
        ... on ContentfulDetailGrid {
          id
          items {
            title
            content {
              raw
            }
          }
          formatsTitle
          formats {
            name
            image {
              ...AssetFragment
              gatsbyImageData(width: 256, layout: FULL_WIDTH)
            }
          }
          videoText {
            raw
          }
          video {
            ...AssetFragment
          }
          videoPreview {
            ...AssetFragment
            gatsbyImageData(width: 256, layout: FULL_WIDTH)
          }
          colorTheme
        }
        ... on ContentfulForm {
          content {
            raw
          }
          secondaryContent {
            raw
          }
          image {
            gatsbyImageData(width: 600, placeholder: BLURRED)
            ...AssetFragment
          }
          decorators {
            ...DecoratorFragment
          }
          form
        }
        ... on ContentfulLinkCards {
          heading
          decorators {
            ...DecoratorFragment
          }
          linkCards {
            heading
            image {
              ...AssetFragment
              gatsbyImageData(width: 768)
            }
            link {
              ...LinkFragment
            }
          }
        }
        ... on ContentfulListGrid {
          heading
          items {
            title
            content {
              raw
            }
          }
        }
        ... on ContentfulProductGrid {
          content {
            raw
          }
          decorators {
            ...DecoratorFragment
          }
          products {
            description {
              raw
            }
            image {
              ...AssetFragment
              small: gatsbyImageData(
                width: 284
                aspectRatio: 1
                resizingBehavior: FILL
                placeholder: BLURRED
                quality: 75
              )
              large: gatsbyImageData(
                width: 698
                placeholder: BLURRED
                quality: 75
              )
            }
            name
          }
        }
        ... on ContentfulProductShowcase {
          showcaseItems {
            name
            image {
              gatsbyImageData(width: 600, placeholder: BLURRED)
              ...AssetFragment
            }
            description {
              raw
            }
          }
        }
        ... on ContentfulSectionHeader {
          header: heading {
            heading
          }
          decorators {
            ...DecoratorFragment
          }
        }
        ... on ContentfulSideBySide {
          heading
          image {
            ...AssetFragment
            gatsbyImageData(width: 600, placeholder: BLURRED)
          }
          decorators {
            ...DecoratorFragment
          }
          content {
            raw
          }
          list {
            value
            title
            description
          }
          button {
            ...LinkFragment
          }
        }
        ... on ContentfulTeamGrid {
          heading
          teamMembers {
            name
            position
            bio {
              raw
            }
            headshot {
              ...AssetFragment
              gatsbyImageData(width: 768, placeholder: BLURRED)
            }
          }
        }
        ... on ContentfulTestimonial {
          quote {
            quote {
              internal {
                content
              }
            }
            attribution
          }
          image {
            ...AssetFragment
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          video {
            ...AssetFragment
          }
          link {
            ...LinkFragment
          }
        }
        ... on ContentfulTextBlock {
          content {
            raw
          }
          button {
            ...LinkFragment
          }
          decorators {
            ...DecoratorFragment
          }
        }
        ... on ContentfulTimeline {
          title
          decorator {
            ...DecoratorFragment
          }
          gallery {
            ...AssetFragment
            gatsbyImageData(width: 288, placeholder: BLURRED)
          }
          timeline {
            value
            title
            description
          }
          button {
            ...LinkFragment
          }
        }
      }
    }
  }
`;

export default OverviewPage;
